import React from 'react'
import "./StartDrive.css"
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function StartDrive({wordsData ,socialData}) {
  return (
    <div className='start-drive'>
        <Container>
            <h5> {wordsData?.words?.Apply_Now} </h5>
            <div className='d-flex align-items-center justify-content-center social-content'>
            <Link to={socialData?.drive_get_started}>
            <button> {wordsData?.words?.get_started} </button>
            </Link>
                    </div>
        </Container>
    </div>
  )
}

export default StartDrive
