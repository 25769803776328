import React, { useEffect, useState } from 'react'
import Slider from '../Components/Slider/Slider'
import RideSection from '../Components/RideSection/RideSection'
import CollectionSection from '../Components/CollectionSection/CollectionSection'
import BecomeDriver from '../Components/BecomeDriver/BecomeDriver'
import UseApp from '../Components/UseApp/UseApp'
import axios from 'axios'
import Loader from './Loader'
import { Helmet } from 'react-helmet';


function Home({language}) {
  const [slidersData, setSlidersData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [wordsData, setWordsData] = useState([]);
  const [bookData, setBookData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const slidersResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/services/sliders`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const servicesResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/services/services`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const wordsResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/pages/home`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const bookResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/services/book_everyday`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const roleResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/services/become_role`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        setSlidersData(slidersResult.data.data);
        setServicesData(servicesResult.data.data);
        setWordsData(wordsResult.data.data);
        setBookData(bookResult.data.data);
        setRoleData(roleResult.data.data);
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [language]);


  return (
    <>
        <Helmet>
        <title> {wordsData?.words?.home_title ? wordsData?.words?.home_title : "Limo"} </title>
      </Helmet>
    
    <div className='home-parent'>
      {loading ? <Loader/> : 
      <>
        <Slider slidersData={slidersData}/>
        <RideSection servicesData={servicesData}/>
      <CollectionSection bookData={bookData} wordsData={wordsData}/>
      <BecomeDriver roleData={roleData}/>
      <UseApp wordsData={wordsData}/>
      </> 
    }
    </div>
    </>
  )
}

export default Home
