import React, { useEffect, useState } from 'react'
import SafetySection1 from '../Components/SafetySection1/SafetySection1'
import SafetySection2 from '../Components/SafetySection2/SafetySection2'
import SafetySection3 from '../Components/SafetySection3/SafetySection3'
import SafetySection4 from '../Components/SafetySection4/SafetySection4'
import LimoManager from '../Components/LimoManager/LimoManager'
import axios from 'axios'
import Loader from './Loader'
import { Helmet } from 'react-helmet';

function Safety({language}) {

  const [wordsData, setWordsData] = useState([]);
  const [bookData, setBookData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const wordsResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/pages/safety`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const bookResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/services/reach_destination`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        setWordsData(wordsResult.data.data);
        setBookData(bookResult.data.data);
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [language]);

  return (
    <>
    <Helmet>
    <title> {wordsData?.words?.safety_title ? wordsData?.words?.safety_title : "Limo"} </title>
  </Helmet>
    <div className='safety-parent'>
      {loading ? <Loader /> :
        <>
          <SafetySection1 wordsData={wordsData} />
          <SafetySection2 wordsData={wordsData} />
          <SafetySection3 wordsData={wordsData} bookData={bookData} />
          <SafetySection4 wordsData={wordsData} />
          <LimoManager wordsData={wordsData} />
        </>}
    </div>
    </>
  )
}

export default Safety
