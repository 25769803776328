import React from 'react'
import "./RideArea.css"
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'


function RideArea({ wordsData, bookData }) {
  return (
    <div className='ride-area'>
      <Container>
        <h5> {wordsData?.words?.Book_Your_Everyday} </h5>
        <Row>
          {bookData?.map(item => (
            <Col md={4} key={item.id}>
              <div className='area-section'>
                <img src={item.image} alt='' />
                <h6> {item.title} </h6>
                <p> {item.description} </p>
                <Link to={item.url}>
                <button> {item.button} </button>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default RideArea
