import React, { useEffect, useState } from 'react'
import StoryHeader from '../Components/StoryHeader/StoryHeader'
import StorySection1 from '../Components/StorySection1/StorySection1'
import StorySection2 from '../Components/StorySection2/StorySection2'
import StorySection3 from '../Components/StorySection3/StorySection3'
import axios from 'axios'
import Loader from './Loader'
import { Helmet } from 'react-helmet';

function OurStory({language}) {

  const [storyData, setStoryData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const storyResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/pages/our-story`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const teamResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/services/employees`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });

        setStoryData(storyResult.data.data);
        setTeamData(teamResult.data.data)
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [language]);

  return (
    <>
    <Helmet>
    <title> {storyData?.words?.story_title ? storyData?.words?.story_title : "Limo"} </title>
  </Helmet>
    <div className='our-story-parent'>
      {loading ? <Loader /> :
        <>
          <StoryHeader storyData={storyData} />
          <StorySection1 storyData={storyData} />
          <StorySection2 storyData={storyData} />
          <StorySection3 storyData={storyData} teamData={teamData} />
        </>}
    </div>
    </>
  )
}

export default OurStory
