import React, { useState } from 'react'
import "./CareerForm.css"
import { Container } from 'react-bootstrap'
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { toast } from "react-toastify";
import axios from 'axios';


function CareerForm({ wordsData }) {

  const [formData, setFormData] = useState({
    name: "",
    biethdate: "",
    nationality: "",
    id_type: "",
    id_number: "",
    email: "",
    phone: "",
    current_situation: "",
    street: "",
    city: "",
    type_time: "",
    position: "",
    start: "",
    qualification_type: "",
    major: "",
    institution: "",
    graduation_date: "",
    experience_job_title: "",
    experience_departMent: "",
    experience_company_name: "",
    experience_duration: "",
    experience_start_date: "",
    experience_end_date: "",
    experience_still_working: "",
    responsibilities_brief: "",
    training_course_certificate: "",
    training_course_certificate_2: "",
    lang_1: "",
    level_lang_1: "",
    lang_2: "",
    level_lang_2: "",
    emergency_name: "",
    emergency_phone: "",
    megastore_worked: "",
    eligible_to_work_saudi: "",
    images: ""
  });

  const [loadingBtn, setLoadingBtn] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const { Dragger } = Upload;
  const props = {
    name: 'file',
    multiple: true,
    action: 'https://limo.za-pen.site/api/upload-file',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
        setFormData({ ...formData, images: info.fileList.map(item=>item?.response?.id) });
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const handleConfirm = async () => {
    try {
      setLoadingBtn(true);
      const result = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/careers`,
        headers: { Accept: "application/json" },
        data: formData,
      });
      successNotify(result.data.message);
      setLoadingBtn(false);
      setFormData({
        name: "",
        biethdate: "",
        nationality: "",
        id_type: "",
        id_number: "",
        email: "",
        phone: "",
        current_situation: "",
        street: "",
        city: "",
        type_time: "",
        position: "",
        start: "",
        qualification_type: "",
        major: "",
        institution: "",
        graduation_date: "",
        experience_job_title: "",
        experience_departMent: "",
        experience_company_name: "",
        experience_duration: "",
        experience_start_date: "",
        experience_end_date: "",
        experience_still_working: "",
        responsibilities_brief: "",
        training_course_certificate: "",
        training_course_certificate_2: "",
        lang_1: "",
        level_lang_1: "",
        lang_2: "",
        level_lang_2: "",
        emergency_name: "",
        emergency_phone: "",
        megastore_worked: "",
        eligible_to_work_saudi: "",
        images: ""
      });
    } catch (error) {
      ErrorNotify(error?.response?.data?.message?.name);
      setLoadingBtn(false);
    }

  };

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Tajawal' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Tajawal' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  return (
    <div className='career-form'>
      <Container>
        <div className='head-card'>
          <h4> {wordsData?.words?.Form} </h4>
        </div>
        <div className='career-card'>
          <h5> {wordsData?.words?.Personal_Information} </h5>
          <div className=''>
            <div className="form-input">
              <h6> {wordsData?.words?.name} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_name}
                value={formData.name}
                name="name"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.birthdate} </h6>
              <input
                type="date"
                value={formData.biethdate}
                name="biethdate"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Nationality} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_Nationality}
                value={formData.nationality}
                name="nationality"
                onChange={handleChange}
              />
            </div>
            <div className='select-option'>
              <h6> {wordsData?.words?.ID_Type}  </h6>
              <select
                name="id_type"
                value={formData.id_type}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Please Select
                </option>
                <option value={wordsData?.words?.ID_Type_select_1}> {wordsData?.words?.ID_Type_select_1} </option>
                <option value={wordsData?.words?.ID_Type_select_2}> {wordsData?.words?.ID_Type_select_2} </option>
                <option value={wordsData?.words?.ID_Type_select_3}> {wordsData?.words?.ID_Type_select_3} </option>
              </select>
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.id_Number} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_id_Number}
                value={formData.id_number}
                name="id_number"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Email} </h6>
              <input
                type="email"
                placeholder={wordsData?.words?.label_Email}
                value={formData.email}
                name="email"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Phone} </h6>
              <input
                type="number"
                placeholder={wordsData?.words?.label_Phone}
                value={formData.phone}
                name="phone"
                onChange={handleChange}
              />
            </div>
            <div className='select-option'>
              <h6> {wordsData?.words?.Current_Situation} </h6>
              <select
                name="current_situation"
                value={formData.current_situation}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Please Select
                </option>
                <option value={wordsData?.words?.situation_select_1}> {wordsData?.words?.situation_select_1} </option>
                <option value={wordsData?.words?.situation_select_2}> {wordsData?.words?.situation_select_2} </option>
                <option value={wordsData?.words?.situation_select_3}> {wordsData?.words?.situation_select_3} </option>
              </select>
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Street} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_Street}
                value={formData.street}
                name="street"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.City} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_City}
                value={formData.city}
                name="city"
                onChange={handleChange}
              />
            </div>
            <h5> {wordsData?.words?.Employment_Request} </h5>
            <div className=" flex-column justify-content-center align-items-start my-5">
              <h6> {wordsData?.words?.Employment_Type} </h6>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="type_time"
                  value="Full Time"
                  id="flexRadioDefault1"
                  checked={formData.type_time === "Full Time"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  {wordsData?.words?.Full_Time}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="type_time"
                  id="flexRadioDefault2"
                  value="Part Time"
                  checked={formData.type_time === "Part Time"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  {wordsData?.words?.Part_Time}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="type_time"
                  id="flexRadioDefault3"
                  value="Seasonal"
                  checked={formData.type_time === "Seasonal"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault3">
                  {wordsData?.words?.Seasonal}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="type_time"
                  id="flexRadioDefault4"
                  value="Co-op Training"
                  checked={formData.type_time === "Co-op Training"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault4">
                  {wordsData?.words?.Co_op_Training}
                </label>
              </div>
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Target_Position} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_Target_Position}
                value={formData.position}
                name="position"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Start} </h6>
              <input
                type="date"
                value={formData.start}
                name="start"
                onChange={handleChange}
              />
            </div>
            <h5> {wordsData?.words?.Education} </h5>
            <div className='select-option'>
              <h6> {wordsData?.words?.Qualification_Type} </h6>
              <select
                name="qualification_type"
                value={formData.qualification_type}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Please Select
                </option>
                <option value={wordsData?.words?.Qualification_select_1}> {wordsData?.words?.Qualification_select_1} </option>
                <option value={wordsData?.words?.Qualification_select_2}> {wordsData?.words?.Qualification_select_2} </option>
                <option value={wordsData?.words?.Qualification_select_3}> {wordsData?.words?.Qualification_select_3} </option>
                <option value={wordsData?.words?.Qualification_select_4}> {wordsData?.words?.Qualification_select_4} </option>
              </select>
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Major} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_Major}
                value={formData.major}
                name="major"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Institution} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_Institution}
                value={formData.institution}
                name="institution"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Graduation_Date} </h6>
              <input
                type="date"
                value={formData.graduation_date}
                name="graduation_date"
                onChange={handleChange}
              />
            </div>
            <h5> {wordsData?.words?.Experience} </h5>
            <div className="form-input">
              <h6> {wordsData?.words?.Job_Title} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_Job_Title}
                value={formData.experience_job_title}
                name="experience_job_title"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.DepartMent} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_DepartMent}
                value={formData.experience_departMent}
                name="experience_departMent"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Company} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_Company}
                value={formData.experience_company_name}
                name="experience_company_name"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Duration} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_Duration}
                value={formData.experience_duration}
                name="experience_duration"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Start_Date} </h6>
              <input
                type="date"
                value={formData.experience_start_date}
                name="experience_start_date"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.End_Date} </h6>
              <input
                type="date"
                value={formData.experience_end_date}
                name="experience_end_date"
                onChange={handleChange}
              />
            </div>
            <div className=" flex-column justify-content-center align-items-start my-5">
              <h6> {wordsData?.words?.If_Youre_Still_Workin} </h6>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="experience_still_working"
                  value="present"
                  id="flexRadioDefault11"
                  checked={formData.experience_still_working === "present"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault11">
                  {wordsData?.words?.Present}
                </label>
              </div>
            </div>
            <h5> {wordsData?.words?.Responsibilities} </h5>
            <p> {wordsData?.words?.brief} </p>
            <div>
              <textarea
                name="responsibilities_brief"
                value={formData.responsibilities_brief}
                onChange={handleChange}
                id=""
                cols="30"
                rows="5"
                placeholder={wordsData?.words?.label_brief}
              ></textarea>
            </div>
            <h5> {wordsData?.words?.Certificates} </h5>
            <div className="form-input">
              <h6> {wordsData?.words?.Course_Certificates}</h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_Course_Certificates}
                value={formData.training_course_certificate}
                name="training_course_certificate"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Course_Certificates_2} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_Course_Certificates_2}
                value={formData.training_course_certificate_2}
                name="training_course_certificate_2"
                onChange={handleChange}
              />
            </div>
            <h5> {wordsData?.words?.Languages} </h5>
            <div className="form-input">
              <h6> {wordsData?.words?.Language_1} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_Language_1}
                value={formData.lang_1}
                name="lang_1"
                onChange={handleChange}
              />
            </div>
            <div className='select-option'>
              <h6> {wordsData?.words?.Fluency_level} </h6>
              <select
                name="level_lang_1"
                value={formData.level_lang_1}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Please Select
                </option>
                <option value={wordsData?.words?.fluency_level_select_1}> {wordsData?.words?.fluency_level_select_1} </option>
                <option value={wordsData?.words?.fluency_level_select_2}> {wordsData?.words?.fluency_level_select_2} </option>
                <option value={wordsData?.words?.fluency_level_select_3}> {wordsData?.words?.fluency_level_select_3} </option>
                <option value={wordsData?.words?.fluency_level_select_4}> {wordsData?.words?.fluency_level_select_4} </option>
                <option value={wordsData?.words?.fluency_level_select_5}> {wordsData?.words?.fluency_level_select_5} </option>
              </select>
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Language_2} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_Language_2}
                value={formData.lang_2}
                name="lang_2"
                onChange={handleChange}
              />
            </div>
            <div className='select-option'>
              <h6> {wordsData?.words?.Fluency_level_Language_2} </h6>
              <select
                name="level_lang_2"
                value={formData.level_lang_2}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Please Select
                </option>
                <option value={wordsData?.words?.fluency_level_select_1}> {wordsData?.words?.fluency_level_select_1} </option>
                <option value={wordsData?.words?.fluency_level_select_2}> {wordsData?.words?.fluency_level_select_2} </option>
                <option value={wordsData?.words?.fluency_level_select_3}> {wordsData?.words?.fluency_level_select_3} </option>
                <option value={wordsData?.words?.fluency_level_select_4}> {wordsData?.words?.fluency_level_select_4} </option>
                <option value={wordsData?.words?.fluency_level_select_5}> {wordsData?.words?.fluency_level_select_5} </option>
              </select>
            </div>
            <h5> {wordsData?.words?.Emergency} </h5>
            <div className="form-input">
              <h6> {wordsData?.words?.Emergency_Name} </h6>
              <input
                type="text"
                placeholder={wordsData?.words?.label_Emergency_Name}
                value={formData.emergency_name}
                name="emergency_name"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <h6> {wordsData?.words?.Emergency_Phone} </h6>
              <input
                type="number"
                placeholder={wordsData?.words?.label_Emergency_Phone}
                value={formData.emergency_phone}
                name="emergency_phone"
                onChange={handleChange}
              />
            </div>
            <div className=" flex-column justify-content-center align-items-start my-5">
              <h6> {wordsData?.words?.Have_you_ever_worked}  </h6>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="megastore_worked"
                  value="Yes"
                  id="flexRadioDefault33"
                  checked={formData.megastore_worked === "Yes"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault33">
                  {wordsData?.words?.Yes}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="megastore_worked"
                  id="flexRadioDefault22"
                  value="No"
                  checked={formData.megastore_worked === "No"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault22">
                  {wordsData?.words?.no}
                </label>
              </div>

            </div>
            <div className=" flex-column justify-content-center align-items-start my-5">
              <h6> {wordsData?.words?.Are_you_legally} </h6>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="eligible_to_work_saudi"
                  value="Yes"
                  id="flexRadioDefault33"
                  checked={formData.eligible_to_work_saudi === "Yes"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault33">
                  {wordsData?.words?.Yes}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="eligible_to_work_saudi"
                  id="flexRadioDefault22"
                  value="No"
                  checked={formData.eligible_to_work_saudi === "No"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault22">
                  {wordsData?.words?.no}
                </label>
              </div>

            </div>
            <div className='upload-sec'>
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text"> Browse Files </p>
                <p className="ant-upload-hint">
                  Drage and drop files here
                </p>
              </Dragger>
            </div>

          </div>

        </div>
        <div className='head-card text-center'>
          <button onClick={handleConfirm}> {loadingBtn ? <><span class="loader-btn2"></span></> : wordsData?.words?.submit} </button>
        </div>
      </Container>
    </div>
  )
}

export default CareerForm
