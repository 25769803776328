import React from 'react'
import "./DriveMostQuestions.css"
import { Container } from 'react-bootstrap'
import Accordion from "react-bootstrap/Accordion";


function DriveMostQuestions({ wordsData, faqsData }) {

  return (
    <div className='most-questions'>
      <Container>
        <h5> {wordsData?.words?.FAQs} </h5>
        <div>
          {faqsData?.map(item => (
            <Accordion defaultActiveKey="0" key={item.id}>
              <Accordion.Item eventKey="1">
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body>
                  {item.description}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}

        </div>
      </Container>
    </div>
  )
}

export default DriveMostQuestions
