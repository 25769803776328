import React from 'react'
import "./StoryHeader.css"
import {  Container } from 'react-bootstrap'


function StoryHeader({storyData}) {
    return (
        <div className='story-parent'>
            <div className="header">
                <div className="transperant-bg">
                    <h5> {storyData?.words?.simplifying_everyday} </h5>
                </div>
            </div>
            <div className='story-content'>
                <Container>
                    <h6> {storyData?.words?.our_story} </h6>
                    <p> {storyData?.words?.our_story_desc} </p>
                </Container>
            </div>
            <div className="phone-img">
                <img src={storyData?.images?.Our_Story} alt='' />
            </div>
        </div>
    )
}

export default StoryHeader
