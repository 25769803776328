import React from 'react'
import "./DriveHeader.css"
import { Link } from 'react-router-dom'

function DriveHeader({wordsData ,socialData}) {
  return (
    <div className='drive-header'>
    <div className="header">
        <div className="transperant-bg">
            <div className='head-content'>
            <h5> {wordsData?.words?.Drive_with_Limo} </h5>
            <h4> {wordsData?.words?.part_of_an_extensive} </h4>
            <Link to={socialData?.driver_download_app_url}>
        <button> {wordsData?.words?.download_app} </button>
        </Link>
            </div>
        </div>
    </div>
</div>
  )
}

export default DriveHeader
