import React, { useEffect, useState } from 'react'
import DriveMostQuestions from '../Components/DriveMostQuestions/DriveMostQuestions'
import DriveHeader from '../Components/DriveHeader/DriveHeader'
import WhyLimoDrive from '../Components/WhyLimoDrive/WhyLimoDrive'
import DriveSection from '../Components/DriveSection/DriveSection'
import DriverPartner from '../Components/DriverPartner/DriverPartner'
import StartDrive from '../Components/StartDrive/StartDrive'
import axios from 'axios'
import Loader from './Loader'
import { Helmet } from 'react-helmet';

function Drive({language}) {

  const [wordsData, setWordsData] = useState([]);
  const [faqsData, setFaqsData] = useState([]);
  const [socialData, setSocialData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const wordsResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/pages/drive`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const faqsResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/services/faqs_drive`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const socialResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/settings`,
          headers: {
              Accept: "application/json",
              lang: language
          },
      });
        setWordsData(wordsResult.data.data);
        setFaqsData(faqsResult.data.data);
        setSocialData(socialResult.data.data);
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [language]);

  return (
    <>
    <Helmet>
    <title> {wordsData?.words?.drive_title ? wordsData?.words?.drive_title : "Limo"} </title>
  </Helmet>
    <div className='drive-parent'>
      {loading ? <Loader /> :
        <>
          <DriveHeader wordsData={wordsData} socialData={socialData}/>
          <WhyLimoDrive wordsData={wordsData} />
          <DriveSection wordsData={wordsData} />
          <DriverPartner wordsData={wordsData} />
          <StartDrive wordsData={wordsData} socialData={socialData}/>
          <DriveMostQuestions wordsData={wordsData} faqsData={faqsData} />
        </>}
    </div>
    </>
  )
}

export default Drive
