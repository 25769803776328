import React from 'react'
import "./RideHeader.css"
import { Link } from 'react-router-dom'

function RideHeader({wordsData ,socialData}) {
    return (
        <div className='ride-header'>
            <div className="header">
                <div className="transperant-bg">
                    <div className='head-content'>
                    <h5> {wordsData?.words?.Let_us_take_you} </h5>
                    <h4> {wordsData?.words?.Sign_up_now} </h4>
                    <Link to={socialData?.ride_download_app_url}>
                <button> {wordsData?.words?.download_app} </button>
                </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RideHeader
