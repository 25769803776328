import React from 'react'
import "./WhyLimo.css"
import { Col, Container, Row } from 'react-bootstrap'
import { MdSafetyCheck } from "react-icons/md";
import { SiFreedesktopdotorg } from "react-icons/si";
import { RiCustomerService2Fill } from "react-icons/ri";
import { MdPayments } from "react-icons/md";
import { FaCar } from "react-icons/fa";
import { RiGpsFill } from "react-icons/ri";


function WhyLimo({wordsData}) {
  return (
    <div className='why-limo'>
        <Container>
      <h5> {wordsData?.words?.why_choose_limo} </h5>
      <div className='d-flex align-items-center justify-content-between'>
      <img src={wordsData?.images?.why_choose_limo} alt='' />
        <Row>
            <Col md={6} >
                <div className='limo-feature'>
                    <h6> <span> <MdSafetyCheck className='icon'/> </span> {wordsData?.words?.Safety} </h6>
                    <p> {wordsData?.words?.Safety_desc} </p>
                </div>
            </Col>
            <Col md={6}>
                <div className='limo-feature'>
                    <h6> <span> <SiFreedesktopdotorg className='icon'/> </span> {wordsData?.words?.Freedom} </h6>
                    <p> {wordsData?.words?.Freedom_desc} </p>
                </div>
            </Col>
            <Col md={6}>
                <div className='limo-feature'>
                    <h6> <span> <RiCustomerService2Fill className='icon'/> </span> {wordsData?.words?.Customer_Support} </h6>
                    <p> {wordsData?.words?.Customer_Support_desc} </p>
                </div>
            </Col>
            <Col md={6}>
                <div className='limo-feature'>
                    <h6> <span> <FaCar className='icon'/> </span> {wordsData?.words?.Rides_on_Demand} </h6>
                    <p> {wordsData?.words?.Rides_on_Demand_desc} </p>
                </div>
            </Col>
            <Col md={6}>
                <div className='limo-feature'>
                    <h6> <span> <MdPayments className='icon'/> </span> {wordsData?.words?.Multiple_Payment_Options} </h6>
                    <p> {wordsData?.words?.Multiple_Payment_Options_desc} </p>
                </div>
            </Col>
            <Col md={6}>
                <div className='limo-feature'>
                    <h6> <span> <RiGpsFill className='icon' /> </span> {wordsData?.words?.Track_Your_Ride} </h6>
                    <p> {wordsData?.words?.Track_Your_Ride_desc} </p>
                </div>
            </Col>
        </Row>
    
      </div>
      </Container>
    </div>
  )
}

export default WhyLimo
