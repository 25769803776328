import React, { useEffect, useState } from 'react'
import "./SafetySection3.css"
import { Card, Container } from 'react-bootstrap'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

function SafetySection3({wordsData ,bookData}) {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [carouselProps, setCarouselProps] = useState({
    interval: 2000,
    autoPlay: true,
    infiniteLoop: true,
    centerMode: true,
    centerSlidePercentage: 75,
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setCarouselProps({
        ...carouselProps,
        centerMode: false,
        centerSlidePercentage: 0,
      });
    } else {
      setCarouselProps({
        ...carouselProps,
        centerMode: true,
        centerSlidePercentage: 75,
      });
    }
  }, [isMobile]);

  return (
    <div className='safety-section3'>
      <Container>
        <h5> {wordsData?.words?.Reach_Your_Destination} </h5>
        <div className='carousel-parent'>
        <Carousel {...carouselProps} className='carousel-safety'>

          {bookData?.map(item=>(
      <div key={item.id}>
      <Card>
    <Card.Img variant="top" src={item.image} />
    <Card.Body>
      <Card.Title> {item.title} </Card.Title>
      <div className="head-title">
        <p> {item.description} </p>
      </div>
    </Card.Body>
  </Card>
      </div>
          ))}
            </Carousel>
            </div>
      </Container>
    </div>
  )
}

export default SafetySection3
