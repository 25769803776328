import React, { useEffect, useState } from 'react'
import Parse from "html-react-parser";
import axios from 'axios';
import Loader from './Loader';
import { Helmet } from 'react-helmet';

function Privacy({language}) {

  const [wordsData, setWordsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
        try {
            setLoading(true);
            const wordsResult = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_BASE_URL}/privacy`,
                headers: {
                    Accept: "application/json",
                    lang: language
                },
            });
            setWordsData(wordsResult.data.data);
            setLoading(false);
        } catch (err) {
        }
    })();
}, [language]);

  return (
    <>
    <Helmet>
    <title> {wordsData?.privacy_title ? wordsData?.privacy_title : "Limo"} </title>
  </Helmet>
    <div className='privacy-parent container py-4'>
       {loading ? <Loader/> :
       <>
      {Parse(`${wordsData?.privacy}`)}
      </>
  }
    </div>
    </>
  )
}

export default Privacy
