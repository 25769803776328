import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBarr from './Components/NavBar/NavBarr';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home';
import OurStory from './Pages/OurStory';
import Ride from './Pages/Ride';
import Drive from './Pages/Drive';
import Careers from './Pages/Careers';
import Safety from './Pages/Safety';
import Airports from './Pages/Airports';
import Cities from './Pages/Cities';
import ContactUs from './Pages/ContactUs';
import IncentiveProgram from './Pages/IncentiveProgram';
import { useEffect, useState } from 'react';
import NotFound from './Components/NotFound/NotFound';
import ScrollToTop from './Pages/ScrollTop';
import TermConditions from './Pages/TermConditions';
import Privacy from './Pages/Privacy';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { Helmet } from 'react-helmet';


function App() {

  const [language, setLanguage] = useState(localStorage.getItem("limo-lang") ? localStorage.getItem("limo-lang") : navigator.language.startsWith('ar') ? 'ar' : 'en');

  const [socialData, setSocialData] = useState([]);

  useEffect(() => {
      (async () => {
          try {
              const socialResult = await axios({
                  method: "GET",
                  url: `${process.env.REACT_APP_BASE_URL}/settings`,
                  headers: {
                      Accept: "application/json",
                  },
              });
              setSocialData(socialResult.data.data);
          } catch (err) {
          }
      })();
  }, []);

  return (
    <>
    {socialData &&  <Helmet>
    <link rel="icon" href={socialData.favicon} />
  </Helmet> }
   
    <div className={language === "en" ? "App ltr" : "App rtl"} style={{direction : language === "en" ? "ltr" : "rtl"}}>
       <BrowserRouter>
       <ScrollToTop/>
       <NavBarr setLanguage={setLanguage} language={language}/>
        <Routes>
          <Route path="/" element={<Home language={language}/>}/>
          <Route path="/our-story" element={<OurStory language={language}/>}/>
          <Route path="/ride" element={<Ride language={language}/>}/>
          <Route path="/drive" element={<Drive language={language}/>}/>
          <Route path="/careers" element={<Careers language={language}/>}/>
          <Route path="/safety" element={<Safety language={language}/>}/>
          <Route path="/cities" element={<Cities language={language}/>}/>
          <Route path="/airports" element={<Airports language={language}/>}/>
          <Route path="/contact-us" element={<ContactUs language={language}/>}/>
          <Route path="/incentive-program" element={<IncentiveProgram language={language}/>}/>
          <Route path="/privacy" element={<Privacy language={language}/>}/>
          <Route path="/term-conditions" element={<TermConditions language={language}/>}/>
          <Route path="*" element={<NotFound />}
          />
        </Routes>
        <Footer language={language}/>
      </BrowserRouter>
      <ToastContainer
        style={{
          marginTop: "12px",
          textAlign: "center",
          zIndex: "99999999999999",
        }}
      />
    </div>
    </>
  );
}

export default App;
