import React from 'react'

function Loader() {
    return (
        <div className='loader-parent spinner-container loader__wrapper'>
 
  <div className="loader__wrapper d-flex flex-column">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width="220px"
    height="220px"
    id="spiner-loader"
    className='spinner-svg '
  >
    <g className="ldl-scale">
      <g className="ldl-ani">
        <g className="ldl-layer">
          <g
            className="ldl-ani"
            style={{
              transformOrigin: '50px 50px',
              animation: '1.11111s linear -0.595238s infinite normal forwards running static-e49060e4-4a11-498b-baaf-f4c3697d5849',
            }}
          >
            <path
              fill="#e15b64"
              d="M76.8 46.7l-12.1 2.2c-.5-4.5-5-10-13.4-15.9-.7-.9-.6-2.1.3-2.8.7-.5 1.6-.5 2.2 0l23 16.5z"
              style={{ fill: 'rgb(47, 109, 181)' }}
            />
          </g>
        </g>
        <g className="ldl-layer">
          <g
            className="ldl-ani"
            style={{
              transformOrigin: '50px 50px',
              animation: '1.11111s linear -0.634921s infinite normal forwards running static-e49060e4-4a11-498b-baaf-f4c3697d5849',
            }}
          >
            <path
              fill="#333"
              d="M38.4 44.9H17c0-2.6 2.1-4.7 4.7-4.7h12.1c2.5 0 4.6 2.1 4.6 4.7z"
              style={{ fill: 'rgb(47, 109, 181)' }}
            />
          </g>
        </g>
        <g className="ldl-layer">
          <g
            className="ldl-ani"
            style={{
              transformOrigin: '50px 50px',
              animation: '1.11111s linear -0.674603s infinite normal forwards running static-e49060e4-4a11-498b-baaf-f4c3697d5849',
            }}
          >
            <path
              fill="#f47e60"
              d="M87.8 54c-.2-.4-.3-.7-.5-1.1-.7-1.5-1.4-3-2.5-4.2-2.3-2.7-5.8-4.2-9.3-4.3-4.2-.1-8.4 0-12.6 0H15.5c-3.1 0-5.7 2.2-6.2 5.2 0 .1-.1.2-.1.3l-.6 3.9-.8 5.4c0 2.2 1.8 4.1 4.1 4.1H89c.7 0 1.9.2 1.6-.8-.7-2.9-1.7-5.7-2.8-8.5z"
              style={{ fill: 'rgb(47, 109, 181)' }}
            />
          </g>
        </g>
        <g className="ldl-layer">
          <g
            className="ldl-ani"
            style={{
              transformOrigin: '50px 50px',
              animation: '1.11111s linear -0.714286s infinite normal forwards running static-e49060e4-4a11-498b-baaf-f4c3697d5849',
            }}
          >
            <path
              fill="#c33737"
              d="M13.5 49.6h-4c-.3 0-.5.2-.5.4l-.5 3.4c0 .3.2.5.4.5h4.6c1.1 0 1.9-.8 1.9-1.9v-.5c0-1-.9-1.9-1.9-1.9z"
              style={{ fill: '#333' }}
            />
          </g>
        </g>
        <g className="ldl-layer">
          <g
            className="ldl-ani"
            style={{
              transformOrigin: '50px 50px',
              animation: '1.11111s linear -0.753968s infinite normal forwards running static-e49060e4-4a11-498b-baaf-f4c3697d5849',
            }}
          >
            <path
              fill="#e0e0e0"
              d="M88 51.5c-.3-1.1-1.3-1.9-2.5-1.9H84c-.8 0-1.4.6-1.4 1.3v1.6c0 .7.6 1.3 1.4 1.3h3.1c.8 0 1.4-.8 1.2-1.5l-.3-.8z"
              style={{ fill: 'rgb(40, 41, 47)' }}
            />
          </g>
        </g>
        <g className="ldl-layer">
          <g
            className="ldl-ani"
            style={{
              transformOrigin: '50px 50px',
              animation: '1.11111s linear -0.793651s infinite normal forwards running static-e49060e4-4a11-498b-baaf-f4c3697d5849',
            }}
          >
            <path
              fill="#e15b64"
              d="M90.3 58.9H7.9s-.4 0-.4.4c0 2.4 1.7 4.1 4.2 4.1h78.5c1.2 0 2.2-1 2.2-2.2.1-1.3-.9-2.3-2.1-2.3z"
              style={{ fill: 'rgb(40, 41, 47)' }}
            />
          </g>
        </g>
        <g className="ldl-layer">
          <g>
            <g className="ldl-layer">
              <g
                className="ldl-ani"
                style={{
                  transformOrigin: '50px 50px',
                  animation: '1.11111s linear -0.833333s infinite normal forwards running static-e49060e4-4a11-498b-baaf-f4c3697d5849',
                }}
              >
                <circle fill="#fff" r="7.5" cy="61.2" cx="23.9" />
              </g>
            </g>
            <g className="ldl-layer">
              <g
                className="ldl-ani"
                style={{
                  transformOrigin: '50px 50px',
                  animation: '1.11111s linear -0.873016s infinite normal forwards running static-e49060e4-4a11-498b-baaf-f4c3697d5849',
                }}
              >
                <path
                  fill="#333"
                  d="M23.9 55.3c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6c0-3.4 2.7-6 6-6m0-3c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9z"
                  style={{ fill: 'rgb(40, 41, 47)' }}
                />
              </g>
            </g>
          </g>
        </g>
        <g className="ldl-layer">
          <g
            className="ldl-ani"
            style={{
              transformOrigin: '50px 50px',
              animation: '1.11111s linear -0.912698s infinite normal forwards running static-e49060e4-4a11-498b-baaf-f4c3697d5849',
            }}
          >
            <path
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeWidth="3"
              stroke="#e15b64"
              fill="#fff"
              d="M26.3 49c3.6.7 6.7 2.8 8.7 5.7"
              style={{ fill: 'rgb(47, 109, 181)', stroke: 'rgb(40, 41, 47)' }}
            />
          </g>
        </g>
        <g className="ldl-layer">
          <g>
            <g className="ldl-layer">
              <g
                className="ldl-ani"
                style={{
                  transformOrigin: '50px 50px',
                  animation: '1.11111s linear -0.952381s infinite normal forwards running static-e49060e4-4a11-498b-baaf-f4c3697d5849',
                }}
              >
                <circle fill="#e6e6e6" r="4" cy="61.2" cx="23.9" />
              </g>
            </g>
          </g>
        </g>
        <g className="ldl-layer">
          <g>
            <g className="ldl-layer">
              <g
                className="ldl-ani"
                style={{
                  transformOrigin: '50px 50px',
                  animation: '1.11111s linear -0.992063s infinite normal forwards running static-e49060e4-4a11-498b-baaf-f4c3697d5849',
                }}
              >
                <circle fill="#fff" r="7.5" cy="61.2" cx="71.5" />
              </g>
            </g>
            <g className="ldl-layer">
              <g
                className="ldl-ani"
                style={{
                  transformOrigin: '50px 50px',
                  animation: '1.11111s linear -1.03175s infinite normal forwards running static-e49060e4-4a11-498b-baaf-f4c3697d5849',
                }}
              >
                <path
                  fill="#333"
                  d="M71.5 55.3c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6c0-3.4 2.7-6 6-6m0-3c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9z"
                  style={{ fill: 'rgb(40, 41, 47)' }}
                />
              </g>
            </g>
          </g>
        </g>
        <g className="ldl-layer">
          <g>
            <g className="ldl-layer">
              <g
                className="ldl-ani"
                style={{
                  transformOrigin: '50px 50px',
                  animation: '1.11111s linear -1.07143s infinite normal forwards running static-e49060e4-4a11-498b-baaf-f4c3697d5849',
                }}
              >
                <circle fill="#e6e6e6" r="4" cy="61.2" cx="71.5" />
              </g>
            </g>
          </g>
        </g>
        <g className="ldl-layer">
          <g
            className="ldl-ani"
            style={{
              transformOrigin: '50px 50px',
              animation: '1.11111s linear -1.11111s infinite normal forwards running static-e49060e4-4a11-498b-baaf-f4c3697d5849',
            }}
          >
            <path
              fill="#e15b64"
              d="M58.7 56.6H35.2c-.7 0-1.2-.5-1.2-1.2v-.3c0-.7.5-1.2 1.2-1.2h23.5c.7 0 1.2.5 1.2 1.2v.3c0 .7-.5 1.2-1.2 1.2z"
              style={{ fill: 'rgb(40, 41, 47)' }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
    <ul className="loader p-0 m-0">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        
    </ul>
</div>
        </div>
    )
}

export default Loader
