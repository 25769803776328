import React from 'react'
import "./TravelPartener.css"
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function TravelPartener({wordsData}) {
    return (
        <div className='travel-partener'>
            <div className='content-section'>
                <Container>
                    <h4> {wordsData?.words?.Your_Traveling_Partner} </h4>
                    <Row>
                        <Col md={6}>
                            <Card>
                                <Card.Img variant="top" src={wordsData?.images?.Traveling_Partner_1} />
                                <Card.Body>
                                    <h6> {wordsData?.words?.partner_title_1} </h6>
                                    <p> {wordsData?.words?.partner_desc_1} </p>
                                    <Link to="/cities">
                                    <button> {wordsData?.words?.partner_button_1} </button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6}>
                            <Card>
                                <Card.Img variant="top" src={wordsData?.images?.Traveling_Partner_2} />
                                <Card.Body>
                                    <h6> {wordsData?.words?.partner_title_2} </h6>
                                    <p> {wordsData?.words?.partner_desc_2}  </p>
                                    <Link to="/airports">
                                    <button> {wordsData?.words?.partner_button_2} </button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default TravelPartener
