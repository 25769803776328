import React from 'react'
import "./SafetySection1.css"
import { Col, Container, Row } from 'react-bootstrap'

function SafetySection1({wordsData}) {
  return (
    <div className='safety-section1'>
      <Container>
        
        <Row>
            <Col md={7}>
            <h5> {wordsData?.words?.Committed} </h5>
                <p> {wordsData?.words?.Committed_desc_1}  </p>
                <p> {wordsData?.words?.Committed_desc_2} </p>
            </Col>
            <Col md={5}>
                <img src={wordsData?.images?.Safety} alt=""/>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SafetySection1
