import React from 'react'
import "./CareerHeader.css"
import { Col, Container, Row } from 'react-bootstrap'

function CareerHeader({wordsData}) {
  return (
    <div className='career-header'>
        <Container>
            <h5> {wordsData?.words?.careers} </h5>
        <Row className='row1'>
            <Col md={7}>
                <h6> {wordsData?.words?.Take_Your_Career} </h6>
                <p> {wordsData?.words?.Take_Your_Career_desc_1} </p>
                <p> {wordsData?.words?.Take_Your_Career_desc_2} </p>
            </Col>
            <Col md={5}>
                <Row>
                    <Col md={6}>
                    <img src={wordsData?.images?.image_1} alt=""/>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col xs={6} md={12}>
                            <img src={wordsData?.images?.image_2} alt=""/>
                            </Col>
                            <Col xs={6} md={12}>
                            <img src={wordsData?.images?.image_3} alt=""/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CareerHeader
