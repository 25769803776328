import React from 'react'
import "./AirportsPlaces.css"
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function AirportsPlaces({airPortData ,wordsData ,socialData}) {
  return (
    <div className='airport-places'>
         <Container>
        <Row className='head-section'>
            <Col md={6}>
            <h5> {wordsData?.words?.Ready_to_Welcome} </h5>
                <p> {wordsData?.words?.Ready_to_Welcome_desc}  </p>
                <Link to={socialData?.airport_request_ride}>
                <button> {wordsData?.words?.request_ride} </button>
                </Link>
            </Col>
            <Col md={6}>
                <img src={wordsData?.images?.top_image} alt=""/>
            </Col>
        </Row>
        <Row>
        <Col md={12}>
                <h5> {wordsData?.words?.airports} </h5>
                {airPortData?.map(item=>(
                  <div key={item.id}>
                <p> ◆	{item.title} </p>
                  </div>
                ))}
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AirportsPlaces
