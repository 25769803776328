import React, { useEffect, useState } from 'react'
import "./UseApp.css"
import { Container } from 'react-bootstrap'
import appleStoreLogo from "../../Assets/appstore.webp"
import playStoreLogo from "../../Assets/playstore.webp"
import axios from 'axios'
import { Link } from 'react-router-dom'

function UseApp({wordsData}) {

  const [socialData, setSocialData] = useState([]);

  useEffect(() => {
      (async () => {
          try {
              const socialResult = await axios({
                  method: "GET",
                  url: `${process.env.REACT_APP_BASE_URL}/settings`,
                  headers: {
                      Accept: "application/json",
                  },
              });
              setSocialData(socialResult.data.data);
          } catch (err) {
          }
      })();
  }, []);


  return (
    <div className='use-app'>
        <Container>
            <h5> {wordsData?.words?.Let_us_take_you} </h5>
            <div className='d-flex align-items-center justify-content-center social-content'>
            <Link to={socialData?.google_play_url}>
                        <button> <img src={playStoreLogo} alt='' /> </button>
                        </Link>
                        <Link to={socialData?.app_store_url}>
                        <button> <img src={appleStoreLogo} alt='' /> </button>
                        </Link>
                    </div>
        </Container>
      
    </div>
  )
}

export default UseApp
