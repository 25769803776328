import React from 'react'
import "./SafetySection2.css"
import { Col, Container, Row } from 'react-bootstrap'

function SafetySection2({wordsData}) {
  return (
    <div className='safety-section2'>
      <Container>
      <h5> {wordsData?.words?.Safety} </h5>
        <Row className='head-sec'>
            <Col md={7}>
              <div>
              <h6> ●	{wordsData?.words?.Safety_title_1} </h6>
                <p> {wordsData?.words?.Safety_desc_1} </p>
              </div>
              <div>
              <h6> ●	{wordsData?.words?.Safety_title_2}  </h6>
                <p> {wordsData?.words?.Safety_desc_2} </p>
              </div>
            </Col>
            <Col md={5}>
              <Row className='sec2'>
                <Col xs={6} md={6}>
                <img src={wordsData?.images?.Safety_Guaranteed_1} alt=""/>
                </Col>
                <Col xs={6} md={6}>
                <img src={wordsData?.images?.Safety_Guaranteed_2} alt=""/>
                </Col>
              </Row>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SafetySection2
