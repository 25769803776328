import React from 'react'
import "./CitiesPlaces.css"
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function CitiesPlaces({wordsData,cityData ,socialData}) {
  return (
    <div className='city-places'>
         <Container>
        <Row className='head-section'>
            <Col md={6}>
            <h5> {wordsData?.words?.Travel_by_Limo} </h5>
                <p> {wordsData?.words?.Travel_by_Limo_desc} </p>
                <Link to={socialData?.city_request_ride}>
                <button> {wordsData?.words?.request_ride} </button>
                </Link>
            </Col>
            <Col md={6}>
                <img src={wordsData?.images?.top_image} alt=""/>
            </Col>
        </Row>
        <Row>
        <Col md={12}>
                <h5> {wordsData?.words?.cities} </h5>
                {cityData?.map(item=>(
                  <div key={item.id}>
                <p> ◆	{item.title} </p>
                  </div>
                ))}
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CitiesPlaces
