import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import "./Footer.css"
import { AiFillYoutube } from "react-icons/ai";
import { FaLinkedin, FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';
import appleStoreLogo from "../../Assets/appstore.webp"
import playStoreLogo from "../../Assets/playstore.webp"
import axios from 'axios';
import { RiTiktokFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";



function Footer({ language }) {

    const [wordsData, setWordsData] = useState([]);
    const [socialData, setSocialData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const wordsResult = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/pages/footer`,
                    headers: {
                        Accept: "application/json",
                        lang: language
                    },
                });
                const socialResult = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/settings`,
                    headers: {
                        Accept: "application/json",
                        lang: language
                    },
                });
                setWordsData(wordsResult.data.data);
                setSocialData(socialResult.data.data);
                setLoading(false);
            } catch (err) {
            }
        })();
    }, [language]);

    return (
        <> {loading ? "" :
            <div className='footer'>
                <Container>
                    <Row className='footer-content'>
                        <Col md={12}>
                            <svg width="120px" height="30px" viewBox="0 0 138 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28.2299 0.439453H22.8799V10.1394H28.2299C29.9799 10.1394 31.4099 11.5695 31.4099 13.3195V36.5194H41.1099V13.3195C41.1099 6.21946 35.3299 0.439453 28.2299 0.439453Z" fill="white"></path><path d="M9.86993 21V0.25H0.169922V21C0.169922 29.57 7.13993 36.54 15.7099 36.54H27.5899V26.8401H15.7099C12.4899 26.8401 9.86993 24.22 9.86993 21Z" fill="white"></path><path d="M76.2799 0.189453H74.8499C71.8699 0.189453 69.1399 1.21944 66.9699 2.92944C64.7999 1.20944 62.0599 0.189453 59.0899 0.189453H57.6599C50.6399 0.189453 44.9299 5.89943 44.9299 12.9194V36.5194H54.6299V12.9194C54.6299 11.2494 55.9899 9.8894 57.6599 9.8894H59.0899C60.7599 9.8894 62.1199 11.2494 62.1199 12.9194V36.5194V36.9194H71.8199V36.5194V12.9194C71.8199 11.2494 73.1799 9.8894 74.8499 9.8894H76.2799C77.9499 9.8894 79.3099 11.2494 79.3099 12.9194V36.9194H89.0099V12.9194C89.0199 5.89943 83.2999 0.189453 76.2799 0.189453Z" fill="white"></path><path d="M109.43 0.189453C100.47 0.189453 93.1799 7.47945 93.1799 16.4395V20.2794C93.1799 29.2394 100.47 36.5294 109.43 36.5294C118.39 36.5294 125.68 29.2394 125.68 20.2794V16.4395C125.68 7.47945 118.39 0.189453 109.43 0.189453ZM115.98 20.2894C115.98 23.8994 113.04 26.8395 109.43 26.8395C105.82 26.8395 102.88 23.8994 102.88 20.2894V16.4495C102.88 12.8395 105.82 9.89941 109.43 9.89941C113.04 9.89941 115.98 12.8395 115.98 16.4495V20.2894Z" fill="white"></path><path d="M132.05 0.25C129.29 0.25 127.05 2.49 127.05 5.25C127.05 8.01 129.29 10.25 132.05 10.25C134.81 10.25 137.05 8.01 137.05 5.25C137.05 2.49 134.81 0.25 132.05 0.25Z" fill="white"></path></svg>
                        </Col>
                        <Col md={12}>
                            <h5> {wordsData?.words?.help_center} </h5>
                        </Col>
                        <Col xs={12} md={8}>
                            <Row>
                                <Col xs={6} md={4}>
                                    <div>
                                        <h6> {wordsData?.words?.company} </h6>
                                        <Link to="/our-story">
                                            <p> {wordsData?.words?.our_story} </p>
                                        </Link>
                                        <Link to="/careers">
                                            <p> {wordsData?.words?.careers} </p>
                                        </Link>
                                        <Link to="/safety">
                                            <p> {wordsData?.words?.safety} </p>
                                        </Link>
                                        <Link to="/contact-us">
                                            <p> {wordsData?.words?.contact_us} </p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={6} md={4}>
                                    <div>
                                        <h6> {wordsData?.words?.products} </h6>
                                        <Link to="/ride">
                                            <p> {wordsData?.words?.ride} </p>
                                        </Link>
                                        <Link to="/drive">
                                            <p> {wordsData?.words?.drive} </p>
                                        </Link>
                                        <Link to="/incentive-program">
                                            <p> {wordsData?.words?.drive_incentive_program} </p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={6} md={4}>
                                    <div>
                                        <h6> {wordsData?.words?.travel} </h6>
                                        <Link to="/airports">
                                            <p> {wordsData?.words?.airports} </p>
                                        </Link>
                                        <Link to="/cities">
                                            <p> {wordsData?.words?.cities} </p>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className='d-flex align-items-center justify-content-between all-rights mt-4'>
                        <div className='d-flex align-items-center'>
                            <Link to={socialData?.tiktok}>
                            <RiTiktokFill className='media-icons' />
                            </Link>
                            <Link to={socialData?.twitter}>
                            <FaXTwitter style={{ fontSize: "25px" }} className='media-icons' />
                            </Link>
                            <Link to={socialData?.youtube}>
                            <AiFillYoutube className='media-icons' />
                            </Link>
                            <Link to={socialData?.linked_in}>
                            <FaLinkedin className='media-icons' />
                            </Link>
                            <Link to={socialData?.instagram}>
                            <FaInstagram className='media-icons' />
                            </Link>
                        </div>

                        <div className='d-flex align-items-center social-content'>
                        <Link to={socialData?.google_play_url}>
                            <button> <img src={playStoreLogo} alt='' /> </button>
                            </Link>
                            <Link to={socialData?.app_store_url}>
                            <button> <img src={appleStoreLogo} alt='' /> </button>
                            </Link>
                        </div>
                    </div>
                    <div className='mt-2'>
                        <div className="col-12 d-flex justify-content-between align-items-center pt-3">
                            <div className="navbar-nav d-flex flex-row gap-4">
                                <Link to="/privacy">
                                    <p className="nav-link text-white"> {wordsData?.words?.privacy} </p>
                                </Link>
                                <Link to="/term-conditions">
                                    <p className="nav-link text-white"> {wordsData?.words?.terms} </p>
                                </Link>
                            </div>
                            <h6 className="text-white mt-2"> {wordsData?.words?.LIMO_appinc} </h6>
                        </div>
                    </div>
                </Container>
            </div>
        }</>
    )
}

export default Footer
