import React from 'react'
import "./StorySection2.css"
import { Col, Container, Row } from 'react-bootstrap'
import { FiLayers } from "react-icons/fi";



function StorySection2({storyData}) {
  return (
    <div className='story-section2'>
        <Container>
            <h5> {storyData?.words?.our_values} </h5>
        <Row>
            <Col md={7}>
            <div className='our-values'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <h6> <span> <FiLayers className='icon' /> </span> {storyData?.words?.Innovation} </h6>
                            <p> {storyData?.words?.Innovation_desc} </p>
                        </Col>
                        <Col md={6}>
                            <h6> <span> <FiLayers className='icon' /> </span> {storyData?.words?.Diversity} </h6>
                            <p> {storyData?.words?.Diversity_desc} </p>
                        </Col>
                        <Col md={6}>
                            <h6> <span> <FiLayers className='icon' /> </span> {storyData?.words?.Passion} </h6>
                            <p> {storyData?.words?.Passion_desc} </p>
                        </Col>
                        <Col md={6}>
                            <h6> <span> <FiLayers className='icon' /> </span> {storyData?.words?.Respect} </h6>
                            <p> {storyData?.words?.Respect_desc} </p>
                        </Col>
                    </Row>
                </Container>

            </div>
                </Col>
                <Col md={5}>
            <div className="section2">
            <img src={storyData?.images?.Our_Values} alt=''/>
            </div>
            </Col>
        </Row>
        </Container>
     
         
         
    </div>
  )
}

export default StorySection2
