import React from 'react'
import "./SafeLimo.css"
import { Container } from 'react-bootstrap'

function SafeLimo({wordsData}) {
  return (
    <div className='safe-limo'>
         <Container>
      <h5> {wordsData?.words?.Limo_Follows_International} </h5>
        <div className='grid-sec'>
            <div className='col-sec'>
                <div className='limo-feature'>
                    <h6> {wordsData?.words?.Verified_Drivers} </h6>
                    <p> {wordsData?.words?.Verified_Drivers_desc} </p>
                </div>
                <div className='limo-feature'>
                    <h6> {wordsData?.words?.Driver_Ratings} </h6>
                    <p>{wordsData?.words?.Driver_Ratings_desc} </p>
                </div>
            </div>
            <div className='col-sec sec-img'>
            <img src={wordsData?.images?.Safety} alt='' />
            </div>
        </div>
      </Container>
    </div>
  )
}

export default SafeLimo
