import React from 'react'
import "./BecomeDriver.css"
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function BecomeDriver({roleData}) {
    return (
        <div className='become-driver'>
            <div className='content-section'>
                <Row>
                    {roleData?.map(item=>(
                         <Col md={6} key={item.id}>
                         <div className='driver-card' key={item.id}>
                             <img src={item.image} alt='' />
                             <div className='overlay-img'> </div>
                             <div className='text-overlay'>
                                 <h6> {item.title} </h6>
                                 <p> {item.description} </p>
                                 <Link to={item.url}>
                                 <button> {item.button} </button>
                                 </Link>
                             </div>
                         </div>
                     </Col>
                    ))}
                </Row>
            </div>

        </div>
    )
}

export default BecomeDriver
