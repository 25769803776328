import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import axios from 'axios';
import Loader from './Loader';
import { Helmet } from 'react-helmet';

function IncentiveProgram({language}) {

  const [wordsData, setWordsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      (async () => {
          try {
              setLoading(true);
              const wordsResult = await axios({
                  method: "GET",
                  url: `${process.env.REACT_APP_BASE_URL}/pages/incentive-program`,
                  headers: {
                      Accept: "application/json",
                      lang: language
                  },
              });
              setWordsData(wordsResult.data.data);
              setLoading(false);
          } catch (err) {
          }
      })();
  }, [language]);

  return (
    <>
    <Helmet>
    <title> {wordsData?.words?.incentive_title ? wordsData?.words?.incentive_title : "Limo"} </title>
  </Helmet>
    <div className='incentive-program'>
      {loading ? <Loader/> : 
      <Container>
        <Row className='head-sec'>
          <Col xs={12} md={7}>
            <h5> {wordsData?.words?.Redeem_Hard_Work}  </h5>
            <h6> {wordsData?.words?.with_Limo}  </h6>
            <p> {wordsData?.words?.with_Limo_desc}  </p>
            <h2> {wordsData?.words?.Earn_more}  </h2>
          </Col>
          <Col md={5}>
            <img src={wordsData?.images?.top_image} alt=''/>
          </Col>
        </Row>
        <Row>
        <Col xs={12} md={12} className='mb-5'>
          <h6> {wordsData?.words?.Opportunity} </h6>
          <p> {wordsData?.words?.Opportunity_desc} </p>
          </Col>
          <Col md={12} className='mb-5'>
          <h6> {wordsData?.words?.Incentives_Determined} </h6>
          <p> {wordsData?.words?.Incentives_Determined_desc} </p>

<p style={{color:"black" ,fontWeight:"bold" ,marginTop:"15px"}}> {wordsData?.words?.weekly} </p>

<p> ◆ {wordsData?.words?.weekly_desc} </p>

<p style={{color:"black" ,fontWeight:"bold" ,marginTop:"15px"}}> {wordsData?.words?.tiered} </p>

<p> ◆ {wordsData?.words?.tiered_option_1} </p>

<p> ◆ {wordsData?.words?.tiered_option_2} </p>

<p> ◆ {wordsData?.words?.tiered_option_3} </p>

<p> ◆ {wordsData?.words?.tiered_option_4} </p>

<p style={{color:"black" ,fontWeight:"bold" ,marginTop:"15px"}}> {wordsData?.words?.how_it_works} </p>

<p> ◆ {wordsData?.words?.how_it_works_optio_1} </p>
<p> ◆ {wordsData?.words?.how_it_works_optio_2} </p>
<p> ◆ {wordsData?.words?.how_it_works_optio_3} </p>
<p> ◆ {wordsData?.words?.how_it_works_optio_4} </p>
<p> ◆ {wordsData?.words?.how_it_works_optio_5} </p>

<p> ◆ {wordsData?.words?.how_it_works_optio_6} </p>
          </Col>
        </Row>
      </Container>
      }
    </div>
    </>
  )
}

export default IncentiveProgram
