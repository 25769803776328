import React from 'react'
import "./StorySection3.css"
import { Card, Col, Container, Row } from 'react-bootstrap'


function StorySection3({storyData ,teamData}) {
  return (
    <div className='story-section3'>
                  <div className="section3">
                <div className="header3" style={{ backgroundImage : `url(${storyData?.images?.Company_Leadership})`}}>
                    <div className="transperant-bg3">
                        <div className='content-ship container'>
                        <h5> {storyData?.words?.Company_Leadership} </h5>
                        <p> {storyData?.words?.Company_Leadership_desc} </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='team-info'>
                <Container>
                    <Row>
                        {teamData?.map(item=>(
                                <Col xs={6} md={3} key={item.id}>
                                <Card>
                                        <Card.Img variant="top" src={item.image} />
                                        <Card.Body>
                                            <Card.Title> {item.title} </Card.Title>
                                            <div className="head-title">
                                                <p> {item.description} </p>
                                            </div>
                                        </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
    </div>
  )
}

export default StorySection3