import React from 'react'
import "./RideSection.css"
import { Col, Container, Row } from 'react-bootstrap'

function RideSection({servicesData}) {
    return (
        <div className='ride-parent'>
            <Container>
                <Row>
                    {servicesData?.map(item=>(
                          <Col md={4} key={item.id}>
                          <div className='ride-card'>
                          <img src={item.image} alt=''/>
                          <h6> {item.title} </h6>
                          <p> {item.description} </p>
                          </div>
                      </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}

export default RideSection
