import React from 'react'
import "./StorySection1.css"
import { Col, Container, Row } from 'react-bootstrap'
import { FcRatings } from "react-icons/fc";

function StorySection1({storyData}) {
  return (
    <div className='story-section1'>
         <div className="section1">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="part1">
                                <FcRatings className='icon' />
                                <h1> {storyData?.words?.our_vision} </h1>
                                <h3> {storyData?.words?.our_vision_desc} </h3>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="part2">
                                <FcRatings className='icon' />
                                <h1> {storyData?.words?.our_mission}  </h1>
                                <h3> {storyData?.words?.our_mission_desc} </h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
    </div>
  )
}

export default StorySection1
