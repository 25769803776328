import React, { useEffect, useState } from 'react'
import AirportsPlaces from '../Components/AirportsPlaces/AirportsPlaces'
import UseApp from '../Components/UseApp/UseApp'
import axios from 'axios';
import Loader from './Loader';
import { Helmet } from 'react-helmet';

function Airports({language}) {

  const [wordsData, setWordsData] = useState([]);
  const [airPortData, setAirPortData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [socialData, setSocialData] = useState([]);


  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const wordsResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/pages/airports`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const airportResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/services/airports`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const socialResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/settings`,
          headers: {
              Accept: "application/json",
          },
      });
      setSocialData(socialResult.data.data);
        setWordsData(wordsResult.data.data);
        setAirPortData(airportResult.data.data);
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [language]);


  return (
    <>
    <Helmet>
    <title>{wordsData?.words?.airport_title ? wordsData?.words?.airport_title : "Limo"}</title>
  </Helmet>
    <div className='airports-parent'>
      {loading ? <Loader /> :
        <>
          <AirportsPlaces wordsData={wordsData} airPortData={airPortData} socialData={socialData}/>
          <UseApp wordsData={wordsData} />
        </>}
    </div>
    </>
  )
}

export default Airports
