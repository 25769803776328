import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { GrLocation } from "react-icons/gr";
import { SlEnvolope } from "react-icons/sl";
import { BsPhone } from "react-icons/bs";
import axios from 'axios';
import Loader from './Loader';
import { toast } from "react-toastify";
import { Helmet } from 'react-helmet';

function ContactUs({language}) {

    const [contactInfo, setContactInfo] = useState({ name: "", email: "", message: "" });

    const handleChange = (e) => {
        setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
    };

    const [wordsData, setWordsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const wordsResult = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/pages/contact-us`,
                    headers: {
                        Accept: "application/json",
                        lang: language
                    },
                });
                setWordsData(wordsResult.data.data);
                setLoading(false);
            } catch (err) {
            }
        })();
    }, [language]);

        const handleConfirm = async () => {
        try {
            setLoadingBtn(true);
            const result = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BASE_URL}/contact-us`,
                headers: { Accept: "application/json" },
                data: contactInfo,
            });
            successNotify(result.data.message);
            setContactInfo({ name: "", email: "", message: "" });
            setLoadingBtn(false);
        } catch (error) {
            ErrorNotify(error?.response?.data?.message?.message);
            setLoadingBtn(false);
        }

    };
    
    const successNotify = (msg) => {
        toast.success(
          <div className="d-flex justify-content-around align-items-center">
            <h6 style={{ fontFamily: 'Tajawal' }}>{msg}</h6>
          </div>,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      };
    
      const ErrorNotify = (msg) => {
        toast.error(
          <div className="d-flex justify-content-around align-items-center">
            <h6 style={{ fontFamily: 'Tajawal' }}>{msg}</h6>
          </div>,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      };

    return (
        <>
        <Helmet>
        <title> {wordsData?.words?.contact_title ? wordsData?.words?.contact_title : "Limo" } </title>
      </Helmet>
        <div className='contact-parent contact-us'>
            {loading ? <Loader /> :
                <>
                    <Container>
                        <h5> {wordsData?.words?.Contact} </h5>
                        <div className='contact-form'>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div className="form-input">
                                        <h6> {wordsData?.words?.NAME} </h6>
                                        <input
                                            className='contact-input'
                                            type="text"
                                            placeholder={wordsData?.words?.label_NAME}
                                            value={contactInfo.name}
                                            name="name"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="form-input">
                                        <h6> {wordsData?.words?.email} </h6>
                                        <input
                                            className='contact-input'
                                            type="email"
                                            placeholder={wordsData?.words?.label_email}
                                            value={contactInfo.email}
                                            name="email"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} md={12}>
                                    <div className="form-input">
                                        <h6> {wordsData?.words?.message} </h6>
                                        <textarea
                                            className='contact-area'
                                            type="text"
                                            placeholder={wordsData?.words?.label_message}
                                            value={contactInfo.message}
                                            name="message"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <button className='confirm-btn' onClick={handleConfirm}> {loadingBtn ? <><span class="loader-btn"></span></> : wordsData?.words?.send} </button>
                                </Col>
                            </Row>

                        </div>
                        <div className='company-info'>
                            <div>
                                <GrLocation className='info-icon' />
                                <h6> {wordsData?.words?.LOCATION} </h6>
                            </div>

                            <div>
                                <SlEnvolope className='info-icon' />
                                <h6> {wordsData?.words?.SUPPORT_email} </h6>
                            </div>

                            <div>
                                <BsPhone className='info-icon' />
                                <h6> {wordsData?.words?.SUPPORT_email_2}  </h6>
                            </div>
                        </div>
                    </Container>
                </>}
        </div>
        </>
    )
}

export default ContactUs
