import React from 'react'
import "./DriveSection.css"
import { Col, Container, Row } from 'react-bootstrap'
import { IoDocumentText } from "react-icons/io5";
import { PiCarProfileFill } from "react-icons/pi";
import { MdPhoneIphone } from "react-icons/md";


function DriveSection({wordsData}) {
  return (
    <div className='drive-section'>
      <Container>
<h5> {wordsData?.words?.Criteria_Driver} </h5>
<div className='d-flex align-items-center justify-content-center'>
<Row>
    <Col md={12}>
        <div className='text-center'>
    <img src={wordsData?.images?.Becoming_Driver} alt='' />
    </div>
    </Col>
    <Col md={6}>
<h6> <span> <IoDocumentText/> </span> {wordsData?.words?.Documentation} </h6>
<p> {wordsData?.words?.Documentation_desc} </p>
    </Col>
    <Col md={6}>
        <h6> <span> <MdPhoneIphone/> </span> {wordsData?.words?.Smartphone} </h6>
        <p> {wordsData?.words?.Smartphone_desc} </p>
        </Col>
        <Col md={6}>
            <h6> <span> <PiCarProfileFill/> </span> {wordsData?.words?.car} </h6>
            <p> {wordsData?.words?.car_desc1} </p>
            <p className='mb-1'> ◆	{wordsData?.words?.car_desc2}  </p>
            <p className='mb-1'> ◆	{wordsData?.words?.car_desc3}  </p>
            <p className='mb-1'> ◆	{wordsData?.words?.car_desc4}  </p>
            <p className='mb-1'> ◆	{wordsData?.words?.car_desc5}  </p>
        
        </Col>
</Row>
</div>
      </Container>
    </div>
  )
}

export default DriveSection
