import React from 'react'
import "./SafetySection4.css"
import { Card, Container } from 'react-bootstrap'

function SafetySection4({wordsData}) {
  return (
    <div className='safety-section4'>
      <Container>
        <p className='my-0'> {wordsData?.words?.Safety_Measures} </p>
        <h5> {wordsData?.words?.Drive_Confidence} </h5>
          <div className='grid'>
          <div className="col-parent">
            <Card>
              <Card.Img variant="top" src={wordsData?.images?.Drive_Confidence_1} />
              <Card.Body>
                <Card.Title> {wordsData?.words?.Customer_Ratings} </Card.Title>
                <div className="head-title">
                  <p> {wordsData?.words?.Customer_Ratings_desc} </p>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-parent">
            <Card>
              <Card.Img variant="top" src={wordsData?.images?.Drive_Confidence_2} />
              <Card.Body>
                <Card.Title> {wordsData?.words?.GPS_Tracking} </Card.Title>
                <div className="head-title">
                  <p> {wordsData?.words?.GPS_Tracking_desc} </p>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default SafetySection4
