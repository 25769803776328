import React from 'react'
import "./WhyLimoDrive.css"
import { Col, Container, Row } from 'react-bootstrap'
import { SiFreedesktopdotorg } from "react-icons/si";
import { RiCustomerService2Fill } from "react-icons/ri";
import { MdPhoneIphone } from "react-icons/md";
import { RxLapTimer } from "react-icons/rx";
import { RiGpsFill } from "react-icons/ri";


function WhyLimoDrive({wordsData}) {
  return (
    <div className='why-limo-drive'>
           <Container>
      <h5> {wordsData?.words?.Why_Drive_with_limo} </h5>
      <div className='d-flex align-items-center justify-content-between'>
      <img src={wordsData?.images?.why_choose_limo} alt='' />
        <Row>
            <Col md={6}>
                <div className='limo-feature'>
                    <h6> <span> <RxLapTimer className='icon'/> </span> {wordsData?.words?.Be_Your_Own_Boss} </h6>
                    <p> {wordsData?.words?.Be_Your_Own_Boss_desc} </p>
                </div>
            </Col>
            <Col md={6}>
                <div className='limo-feature'>
                    <h6> <span> <SiFreedesktopdotorg className='icon'/> </span> {wordsData?.words?.Driver_Incentive} </h6>
                    <p> {wordsData?.words?.Driver_Incentive_desc} </p>
                </div>
            </Col>
            <Col md={6}>
                <div className='limo-feature'>
                    <h6> <span> <RiCustomerService2Fill className='icon'/> </span> {wordsData?.words?.Earn_Terms} </h6>
                    <p> {wordsData?.words?.Earn_Terms_desc} </p>
                </div>
            </Col>
            <Col md={6}>
                <div className='limo-feature'>
                    <h6> <span> <MdPhoneIphone className='icon'/> </span> {wordsData?.words?.Easy_Mobile_Application} </h6>
                    <p> {wordsData?.words?.Easy_Mobile_Application_desc} </p>
                </div>
            </Col>
            <Col md={6}>
                <div className='limo-feature'>
                    <h6> <span> <RiGpsFill className='icon' /> </span> {wordsData?.words?.Empowering_Platform} </h6>
                    <p> {wordsData?.words?.Empowering_Platform_desc} </p>
                </div>
            </Col>
        </Row>
    
      </div>
      </Container>
    </div>
  )
}

export default WhyLimoDrive
