import React from 'react'
import "./DriverPartner.css"
import { Col, Container, Row } from 'react-bootstrap'

function DriverPartner({wordsData}) {
  return (
    <div className='driver-partner'>
      <Container>
      <h5> {wordsData?.words?.Become_Driver} </h5>
        <Row>
            <Col md={7}>
                <div className='d-flex'>
                    <div className='number'> 1 </div>
                    <div className='register-text'>
                        <h6> {wordsData?.words?.Become_Driver_title_1} </h6>
                        <p> {wordsData?.words?.Become_Driver_desc_1} </p>
                    </div>
                </div>
                <div className='d-flex'>
                <div className='number'> 2 </div>
                    <div className='register-text'>
                        <h6> {wordsData?.words?.Become_Driver_title_2} </h6>
                        <p> {wordsData?.words?.Become_Driver_desc_2} </p>
                    </div>
                </div>
                <div className='d-flex'>
                <div className='number'> 3 </div>
                    <div className='register-text'>
                        <h6> {wordsData?.words?.Become_Driver_title_3} </h6>
                        <p> {wordsData?.words?.Become_Driver_desc_3} </p>
                    </div>
                </div>
            </Col>
            <Col md={5}>
                <img src={wordsData?.images?.Become_Driver_Partner} alt=""/>
                </Col>
        </Row>
      </Container>
    </div>
  )
}

export default DriverPartner
