import React, { useState, useEffect, useRef } from "react";
import { Navbar, Container, Nav, Dropdown, NavDropdown } from "react-bootstrap";
import "./NavBarr.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

function NavBarr({language , setLanguage}) {

  const [wordsData, setWordsData] = useState([]);
  const [socialData, setSocialData] = useState([]);
  const [loading, setLoading] = useState(false);

  const collapseButtonRef = useRef(null);
  const collapseButtonRef2 = useRef(null);

  const location = useLocation();

  const handleArabic =()=>{
    setLanguage("ar")
    localStorage.setItem("limo-lang" ,"ar")
  }

  const handleEnglish =()=>{
    setLanguage("en")
    localStorage.setItem("limo-lang" ,"en")
  }

  useEffect(() => {
      (async () => {
          try {
              setLoading(true);
              const wordsResult = await axios({
                  method: "GET",
                  url: `${process.env.REACT_APP_BASE_URL}/pages/navbar`,
                  headers: {
                      Accept: "application/json",
                      lang: language
                  },
              });
              const socialResult = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_BASE_URL}/settings`,
                headers: {
                    Accept: "application/json",
                    lang: language
                },
            });
              setWordsData(wordsResult.data.data);
              setSocialData(socialResult.data.data);
              setLoading(false);
          } catch (err) {
          }
      })();
  }, [language]);

  const handleCollapse = () => {
    if (collapseButtonRef.current) {
      collapseButtonRef.current.click(); // Close the collapse toggle button
    }
  };

  const handleCollapseDropdown = () => {
    if (collapseButtonRef2.current) {
      collapseButtonRef2.current.click(); // Close the collapse toggle button
    }
  };

  // Function to check if the device is a mobile device
  const isMobileDevice = () => {
    return window.innerWidth <= 768; // Adjust this threshold according to your design
  };

  // Call handleCollapse only on mobile devices
  const handleMobileCollapse = () => {
    if (isMobileDevice()) {
      handleCollapse();
    }
  };

  return (
    <>
       {loading ? "" :
    <Navbar collapseOnSelect expand="lg" className="navbar">
    <Container>
        <Link className="mob-logo" to="/">
          <svg width="120px" height="30px" viewBox="0 0 138 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28.2299 0.439453H22.8799V10.1394H28.2299C29.9799 10.1394 31.4099 11.5695 31.4099 13.3195V36.5194H41.1099V13.3195C41.1099 6.21946 35.3299 0.439453 28.2299 0.439453Z" fill="white"></path><path d="M9.86993 21V0.25H0.169922V21C0.169922 29.57 7.13993 36.54 15.7099 36.54H27.5899V26.8401H15.7099C12.4899 26.8401 9.86993 24.22 9.86993 21Z" fill="white"></path><path d="M76.2799 0.189453H74.8499C71.8699 0.189453 69.1399 1.21944 66.9699 2.92944C64.7999 1.20944 62.0599 0.189453 59.0899 0.189453H57.6599C50.6399 0.189453 44.9299 5.89943 44.9299 12.9194V36.5194H54.6299V12.9194C54.6299 11.2494 55.9899 9.8894 57.6599 9.8894H59.0899C60.7599 9.8894 62.1199 11.2494 62.1199 12.9194V36.5194V36.9194H71.8199V36.5194V12.9194C71.8199 11.2494 73.1799 9.8894 74.8499 9.8894H76.2799C77.9499 9.8894 79.3099 11.2494 79.3099 12.9194V36.9194H89.0099V12.9194C89.0199 5.89943 83.2999 0.189453 76.2799 0.189453Z" fill="white"></path><path d="M109.43 0.189453C100.47 0.189453 93.1799 7.47945 93.1799 16.4395V20.2794C93.1799 29.2394 100.47 36.5294 109.43 36.5294C118.39 36.5294 125.68 29.2394 125.68 20.2794V16.4395C125.68 7.47945 118.39 0.189453 109.43 0.189453ZM115.98 20.2894C115.98 23.8994 113.04 26.8395 109.43 26.8395C105.82 26.8395 102.88 23.8994 102.88 20.2894V16.4495C102.88 12.8395 105.82 9.89941 109.43 9.89941C113.04 9.89941 115.98 12.8395 115.98 16.4495V20.2894Z" fill="white"></path><path d="M132.05 0.25C129.29 0.25 127.05 2.49 127.05 5.25C127.05 8.01 129.29 10.25 132.05 10.25C134.81 10.25 137.05 8.01 137.05 5.25C137.05 2.49 134.81 0.25 132.05 0.25Z" fill="white"></path></svg>
        </Link>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" ref={collapseButtonRef}/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Link className="not-mob-logo" to="/">
            <svg width="120px" height="30px" viewBox="0 0 138 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28.2299 0.439453H22.8799V10.1394H28.2299C29.9799 10.1394 31.4099 11.5695 31.4099 13.3195V36.5194H41.1099V13.3195C41.1099 6.21946 35.3299 0.439453 28.2299 0.439453Z" fill="white"></path><path d="M9.86993 21V0.25H0.169922V21C0.169922 29.57 7.13993 36.54 15.7099 36.54H27.5899V26.8401H15.7099C12.4899 26.8401 9.86993 24.22 9.86993 21Z" fill="white"></path><path d="M76.2799 0.189453H74.8499C71.8699 0.189453 69.1399 1.21944 66.9699 2.92944C64.7999 1.20944 62.0599 0.189453 59.0899 0.189453H57.6599C50.6399 0.189453 44.9299 5.89943 44.9299 12.9194V36.5194H54.6299V12.9194C54.6299 11.2494 55.9899 9.8894 57.6599 9.8894H59.0899C60.7599 9.8894 62.1199 11.2494 62.1199 12.9194V36.5194V36.9194H71.8199V36.5194V12.9194C71.8199 11.2494 73.1799 9.8894 74.8499 9.8894H76.2799C77.9499 9.8894 79.3099 11.2494 79.3099 12.9194V36.9194H89.0099V12.9194C89.0199 5.89943 83.2999 0.189453 76.2799 0.189453Z" fill="white"></path><path d="M109.43 0.189453C100.47 0.189453 93.1799 7.47945 93.1799 16.4395V20.2794C93.1799 29.2394 100.47 36.5294 109.43 36.5294C118.39 36.5294 125.68 29.2394 125.68 20.2794V16.4395C125.68 7.47945 118.39 0.189453 109.43 0.189453ZM115.98 20.2894C115.98 23.8994 113.04 26.8395 109.43 26.8395C105.82 26.8395 102.88 23.8994 102.88 20.2894V16.4495C102.88 12.8395 105.82 9.89941 109.43 9.89941C113.04 9.89941 115.98 12.8395 115.98 16.4495V20.2894Z" fill="white"></path><path d="M132.05 0.25C129.29 0.25 127.05 2.49 127.05 5.25C127.05 8.01 129.29 10.25 132.05 10.25C134.81 10.25 137.05 8.01 137.05 5.25C137.05 2.49 134.81 0.25 132.05 0.25Z" fill="white"></path></svg>
          </Link>
          <div className="d-flex align-items-center justify-content-between navbar-content">
            <Nav className="d-flex justify-content-center">
              <NavDropdown ref={collapseButtonRef2} title={wordsData?.words?.services}  id="basic-nav-dropdown"  className={location.pathname === "/drive" || location.pathname === "/ride" ? "active-drop-links drop-links" : "drop-links"}>
                <Link to="/ride" > <div onClick={() => {handleMobileCollapse();handleCollapseDropdown();}} className={location.pathname === "/ride" ? "active-dlink1 dlink1" : "dlink1"}> <p> {wordsData?.words?.ride}  </p> </div>  </Link>
                <Link to="/drive" > <div onClick={() => {handleMobileCollapse();handleCollapseDropdown();}}  className={location.pathname === "/drive" ? "active-dlink2 dlink2" : "dlink2"}> <p> {wordsData?.words?.drive}  </p> </div>  </Link>
            </NavDropdown>
              <div className="parent_links" onClick={handleMobileCollapse}>
                <Link
                  className={location.pathname === "/our-story" ? "active-link" : "links"}
                  to="/our-story"
                >
                 {wordsData?.words?.our_story}
                </Link>
              </div>
            </Nav>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center nav-btns">
                <Link to={socialData?.drive_with_us_url}>
               <button className="drive-btn"> {wordsData?.words?.drive_with_us}  </button>
               </Link>
               <Link to={socialData?.ride_with_us_url}>
                  <button className="drive-btn"> {wordsData?.words?.ride_with_us}  </button>
                  </Link>
              </div>
              <Dropdown className="drop-language">
                <Dropdown.Toggle className="dropdown-user" >
                  <div>
                    <span><svg height="20" width="20" style={{ marginTop: "-5px" }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.2948 5.44068C15.6348 8.99068 13.8448 12.9507 10.2948 14.2907C6.74483 15.6307 2.78485 13.8407 1.44485 10.2907C0.104852 6.74068 1.89484 2.78068 5.43484 1.44068C8.99484 0.110683 12.9548 1.89068 14.2948 5.44068Z" stroke="#A6CF8F" strokeWidth="1.2" strokeMiterlimit="10"></path><path d="M10.4649 6.89009C11.8049 10.4401 11.7348 13.7501 10.2948 14.2901C8.86481 14.8301 6.61491 12.3901 5.27491 8.85009C3.93491 5.30009 4.00482 1.99009 5.43482 1.45009C6.88482 0.910089 9.12485 3.34009 10.4649 6.89009Z" stroke="#A6CF8F" strokeWidth="1.2" strokeMiterlimit="10"></path><path d="M1.45483 10.2914C1.45483 10.2914 10.7448 8.22141 14.2948 5.44141" stroke="#A6CF8F" strokeWidth="1.2" strokeMiterlimit="10"></path></svg></span>
                    <span className="mx-1"> {language === "ar" ? "AR" : "EN"} </span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu id="drop" className="dropdown-menu">

                  <Dropdown.Item className="dropdown-items" onClick={handleArabic}>
                    <h6> AR </h6>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-items" onClick={handleEnglish}>
                    <h6> EN </h6>
                  </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
     
    </Navbar>
        }</>
  );
}

export default NavBarr;
