import React, { useEffect, useState } from 'react'
import CareerHeader from '../Components/CareerHeader/CareerHeader'
import CareerForm from '../Components/CareerForm/CareerForm'
import axios from 'axios';
import Loader from './Loader';
import { Helmet } from 'react-helmet';

function Careers({language}) {

  const [wordsData, setWordsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const wordsResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/pages/careers`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        setWordsData(wordsResult.data.data);
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [language]);

  return (
    <>
    <Helmet>
    <title> {wordsData?.words?.career_title ? wordsData?.words?.career_title : "Limo"} </title>
  </Helmet>
    <div className='careers-parent'>
      {loading ? <Loader /> :
        <>
          <CareerHeader wordsData={wordsData} />
          <CareerForm wordsData={wordsData} />
        </>
      }
    </div>
    </>
  )
}

export default Careers
