import React, { useEffect, useState } from 'react'
import RideHeader from '../Components/RideHeader/RideHeader'
import WhyLimo from '../Components/WhyLimo/WhyLimo'
import SafeLimo from '../Components/SafeLimo/SafeLimo'
import RideArea from '../Components/RideArea/RideArea'
import TravelPartener from '../Components/TravelPartener/TravelPartener'
import RideMostQuestions from '../Components/RideMostQuestions/RideMostQuestions'
import axios from 'axios'
import Loader from './Loader'
import { Helmet } from 'react-helmet';

function Ride({language}) {

  const [wordsData, setWordsData] = useState([]);
  const [bookData, setBookData] = useState([]);
  const [faqsData, setFaqsData] = useState([]);
  const [socialData, setSocialData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const wordsResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/pages/ride`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const bookResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/services/book_everyday`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const faqsResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/services/faqs_ride`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const socialResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/settings`,
          headers: {
              Accept: "application/json",
              lang: language
          },
      });
        setWordsData(wordsResult.data.data);
        setBookData(bookResult.data.data);
        setFaqsData(faqsResult.data.data);
        setSocialData(socialResult.data.data);
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [language]);

  return (
    <>
    <Helmet>
    <title> {wordsData?.words?.ride_title ? wordsData?.words?.ride_title : "Limo"} </title>
  </Helmet>
    <div className='ride-parent'>
      {loading ? <Loader /> :
        <>
          <RideHeader wordsData={wordsData} socialData={socialData}/>
          <WhyLimo wordsData={wordsData} />
          <RideArea wordsData={wordsData} bookData={bookData} />
          <SafeLimo wordsData={wordsData} />
          <TravelPartener wordsData={wordsData} />
          <RideMostQuestions wordsData={wordsData} faqsData={faqsData} />
        </>}
    </div>
    </>
  )
}

export default Ride
