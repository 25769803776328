import React from 'react'
import "./LimoManager.css"
import { Col, Container, Row } from 'react-bootstrap'

function LimoManager({wordsData}) {
  return (
    <div className='limo-manager'>
      <Container>
        <Row>
            <Col md={7}>
                <p> {wordsData?.words?.With_Limo_desc} </p>
           <h5> {wordsData?.words?.Khaled_Alahmadi} </h5>
           <h6> {wordsData?.words?.CEO} </h6>
            </Col>
            <Col md={5}>
                <img src={wordsData?.images?.person} alt=''/>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LimoManager
