import React, { useEffect, useState } from 'react'
import Parse from "html-react-parser";
import axios from 'axios';
import Loader from './Loader';
import { Helmet } from 'react-helmet';

function TermConditions({language}) {
  
  const [wordsData, setWordsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
        try {
            setLoading(true);
            const wordsResult = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_BASE_URL}/terms`,
                headers: {
                    Accept: "application/json",
                    lang: language
                },
            });
            setWordsData(wordsResult.data.data);
            setLoading(false);
        } catch (err) {
        }
    })();
}, [language]);


  return (
    <>
    <Helmet>
    <title> {wordsData?.terms_title ? wordsData?.terms_title : "Limo"} </title>
  </Helmet>
    <div className='term-conditions container py-4'>
           {loading ? <Loader/> :
       <>
      {Parse(`${wordsData?.terms}`)}
      </>
  }
    </div>
    </>
  )
}

export default TermConditions
