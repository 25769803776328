import React from 'react'
import "./Slider.css"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';

function Slider({slidersData}) {
    return (
        <div className='slider-parent'>
            <Carousel interval="2000" autoPlay infiniteLoop>
                {slidersData?.map(item=>(
                      <div key={item.id}>
                      <img src={item.image} alt='' />
                     <div className="legend">
                     <h6> {item.title} </h6>
                              <h5> {item.description} </h5>
                              <p> {item.description2} </p>
                              <Link to={item.url}>
                              <button> {item.button} </button>
                              </Link>
                     </div>
                  </div>
                ))}
            </Carousel>
        </div>
    )
}

export default Slider
