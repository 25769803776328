import React, { useEffect, useState } from 'react'
import CitiesPlaces from '../Components/CitiesPlaces/CitiesPlaces'
import UseApp from '../Components/UseApp/UseApp'
import axios from 'axios';
import Loader from './Loader';
import { Helmet } from 'react-helmet';

function Cities({language}) {

  const [wordsData, setWordsData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [socialData, setSocialData] = useState([]);


  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const wordsResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/pages/cities`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const cityResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/services/cities`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const socialResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/settings`,
          headers: {
              Accept: "application/json",
          },
      });
      setSocialData(socialResult.data.data);
        setWordsData(wordsResult.data.data);
        setCityData(cityResult.data.data);
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [language]);

  return (
    <>
    <Helmet>
    <title> {wordsData?.words?.city_title ? wordsData?.words?.city_title : "Limo"} </title>
  </Helmet>
    <div className='cities-parent'>
      {loading ? <Loader /> :
        <>
          <CitiesPlaces wordsData={wordsData} cityData={cityData} socialData={socialData} />
          <UseApp wordsData={wordsData} />
        </>}
    </div>
    </>
  )
}

export default Cities
